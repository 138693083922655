// src/pages/MeditationApp/MeditationApp.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import CreateButton from './components/CreateButton';
import VoiceSelector from './components/VoiceSelector';
import VolumeControls from './components/VolumeControls';
import PushToSpeakButton from './components/PushToSpeakButton';
import Preloader from './components/Preloader';
import DecisionTree from './components/DecisionTree';
import AudioPlayer from './components/AudioPlayer';
import ExportButton from './components/ExportButton';
import { ConversationProvider } from './components/ConversationContext';
import MergedTextEditor from './components/MergedTextEditor';
import debounce from 'lodash/debounce';
import Joi from 'joi';
import { ToastContainer, toast, Slide } from 'react-toastify';
import logo from '../../assets/logot.png';
import './App.css';
import './components/Button.css';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header';
import LoadingIndicator from './components/LoadingIndicator';
import { presetsMap } from './components/FilterPresets';

// LoadingController remains unchanged
const LoadingController = {
  startTime: null,
  minimumDuration: 2000,
  start() {
    this.startTime = Date.now();
  },
  shouldKeepLoading() {
    if (!this.startTime) return false;
    return Date.now() - this.startTime < this.minimumDuration;
  },
  getRemainingTime() {
    if (!this.startTime) return 0;
    return Math.max(0, this.minimumDuration - (Date.now() - this.startTime));
  },
  reset() {
    this.startTime = null;
  }
};

const MeditationApp = () => {
  // Base URL from .env
  const baseUrl = useMemo(() => process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site', []);

  // Abort controller to cancel fetch requests
  const abortControllerRef = useRef(null);
  const lastBaseAudioUrlRef = useRef('');

  // Volume states
  const [volume, setVolume] = useState({
    overall_volume: 1,
    tts_volume: 1,
    bg_volume: 0.2
  });

  // Music states
  const [musicFiles, setMusicFiles] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [isMusicLoading, setIsMusicLoading] = useState(false);
  const [isMixingAudio, setIsMixingAudio] = useState(false);
  const [ttsKey, setTTSKey] = useState('');

  const [pendingChanges, setPendingChanges] = useState({
      volume: null,
      filters: null
  });

  // Filters states
  const [filters, setFilters] = useState({
    stereoPan: {
      enabled: false,
      value: 0.5,         // 0-1 range
      speed: 30,          // 5-60 range
      hold_time: 10,      // 0-60 range
      pattern: 'sine',    // matches backend patterns
      preset: ''
    },
    binauralBeats: {
      enabled: false,
      base_frequency: 200,  // 20-500 range
      beat_frequency: 10,   // 0.5-40 range
      volume: 0.5,         // 0-1 range
      preset: ''
    },
    oceanWaves: {
      enabled: false,
      wave_frequency: 0.1,    // 0.05-0.5 range
      noise_amplitude: 0.2,   // 0-1 range
      volume: 0.5,           // 0-1 range
      preset: ''
    },
    reverb: {
      enabled: false,
      value: 0.1,         // 0-1 range
      preset: ''
    }
  });

  const memoizedVolume = useMemo(() => ({
    overall_volume: volume.overall_volume,
    tts_volume: volume.tts_volume,
    bg_volume: volume.bg_volume
  }), [volume.overall_volume, volume.tts_volume, volume.bg_volume]);

  const memoizedMusicLibrary = useMemo(() => musicFiles, [musicFiles]);

  // Schema definition
  const mixingPayloadSchema = useMemo(() => Joi.object({
    bg_volume: Joi.number().min(0).max(1).required(),
    tts_volume: Joi.number().min(0).max(1).required(),
    overall_volume: Joi.number().min(0).max(1).required(),
    background_music_url: Joi.string().required(),
    tts_filename: Joi.string().required(),
    filters: Joi.object().required()
  }), []);

  // Audio states
  const [isMixedAudioLoaded, setIsMixedAudioLoaded] = useState(false);
  const [mixedAudioUrl, setMixedAudioUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isAudioLoading, setIsAudioLoading] = useState(false);

  // Processing states
  const [isLoading, setIsLoading] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordingAnimation, setIsRecordingAnimation] = useState(false);
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);
  const [isTranscribed, setIsTranscribed] = useState(false);
  const [isPersonalizationMode, setIsPersonalizationMode] = useState(false);
  const [isSessionCreated, setIsSessionCreated] = useState(false);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isTTSLoaded, setIsTTSLoaded] = useState(false);
  const [changeType, setChangeType] = useState(null);

  // Refs for isLoading and isMixingAudio to prevent infinite loops
  const isLoadingRef = useRef(isLoading);
  const isMixingAudioRef = useRef(isMixingAudio);

  // Update refs whenever state changes
  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  useEffect(() => {
    isMixingAudioRef.current = isMixingAudio;
  }, [isMixingAudio]);

  // UI states
  const [sidebarState, setSidebarState] = useState({
    isOpen: window.innerWidth > 768,
    hasBackdrop: false
  });
  const [editorMode, setEditorMode] = useState('prompt');
  const [isTextEditorGlowing, setIsTextEditorGlowing] = useState(false);
  const [isEnhancedEditorGlowing, setIsEnhancedEditorGlowing] = useState(false);
  const [showDecisionTree, setShowDecisionTree] = useState(false);
  const [error, setError] = useState({ message: null, type: null });

  // Script & Content states
  const [customPrompt, setCustomPrompt] = useState('');
  const [meditationScript, setMeditationScript] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [personalizedNote, setPersonalizedNote] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('onyx');

  // Backend filters structure
  const filtersBackend = useMemo(() => {
    const currentFilters = {
      ...filters,
      ...(pendingChanges.filters || {})
    };
    
    const backendFilters = {};
    
    Object.entries(currentFilters).forEach(([key, filter]) => {
      if (filter.enabled) {
        backendFilters[key] = { enabled: true };
        
        switch (key) {
          case 'stereoPan':
            backendFilters[key] = {
              enabled: true,
              value: Number(filter.value),
              speed: Number(filter.speed),
              pattern: filter.pattern
            };
            break;
            
          case 'binauralBeats':
            backendFilters[key] = {
              enabled: true,
              base_frequency: Number(filter.base_frequency),
              beat_frequency: Number(filter.beat_frequency), 
              volume: Number(filter.volume)
            };
            break;
            
          case 'oceanWaves':
            backendFilters[key] = {
              enabled: true,
              wave_frequency: Number(filter.wave_frequency),
              noise_amplitude: Number(filter.noise_amplitude),
              volume: Number(filter.volume)
            };
            break;
            
          case 'reverb':
            backendFilters[key] = {
              enabled: true,
              value: Number(filter.value)
            };
            break;
        }
      }
    });
  
    return backendFilters;
  }, [filters, pendingChanges.filters]);

  // Cleans the generated script text
  const cleanText = useCallback((text) => {
    return text
      .replace(/\s*\[PROSODY_START\]\s*|\s*\[PROSODY_END\]\s*|\s*\[PAUSE( LONG)?\]\s*/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  }, []);

  const validateAudioUrl = useCallback((url) => {
    try {
      const parsed = new URL(url);
      const params = parsed.searchParams;

      const hasRequiredParams =
        params.has('AWSAccessKeyId') &&
        params.has('Signature') &&
        params.has('Expires');
      const expiryTimestamp = params.get('Expires');
      const isExpired =
        expiryTimestamp && Date.now() / 1000 > parseInt(expiryTimestamp, 10);

      return {
        isValid: hasRequiredParams && !isExpired,
        baseUrl: `${parsed.origin}${parsed.pathname}`.replace(/\/+$/, '').toLowerCase(),
      };
    } catch (e) {
      return { isValid: false, baseUrl: null };
    }
  }, []);

  // Sets the new audio URL for the AudioPlayer, avoiding repeated loads
  const setNewMixedAudioUrl = useCallback((newUrl) => {
    try {
      const url = new URL(newUrl);
      const baseUrlWithoutParams = `${url.origin}${url.pathname}`.replace(/\/+$/, '').toLowerCase();

      if (baseUrlWithoutParams !== lastBaseAudioUrlRef.current) {
        lastBaseAudioUrlRef.current = baseUrlWithoutParams;
        setMixedAudioUrl(newUrl);
      }
    } catch (error) {
      setError({
        message: 'Received an invalid audio URL from the server.',
        type: 'audio'
      });
      toast.error('Received an invalid audio URL from the server.');
    }
  }, []);

  // =============== DEFINE logControl =============== //
  // Define logControl using useCallback for memoization
  const logControl = useCallback((action, data = {}) => {
    const timestamp = new Date().toISOString();
    console.log(`[MeditationApp ${timestamp}] ${action}:`, data);
  }, []);
  // =============== END logControl =============== //

  // =============== SCRIPT GENERATION HANDLER =============== //
  const handleGenerateScript = useCallback(async (personalizedPrompt = '') => {
    if (isScriptLoading) return;

    try {
      setIsScriptLoading(true);
      LoadingController.start();

      // Reset session-related states
      setIsSessionCreated(false);
      setIsTTSLoaded(false);
      setIsCreatingSession(false);

      const prompt = personalizedPrompt || customPrompt.trim();
      if (!prompt) {
        throw new Error('Cannot generate a script with an empty prompt.');
      }

      console.log('Sending request with data:', {
        prompt,
        selected_options: selectedOptions.map(option => option.label),
        selected_voice: selectedVoice,
        transcription: customPrompt
      });

      const response = await fetch(`${baseUrl}/generate-ssml`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt,
          selected_options: selectedOptions.map(option => option.label),
          selected_voice: selectedVoice,
          transcription: customPrompt,
          service: 'openai'
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Script generation response:', responseData);

      // Check for success status and nested data structure
      if (responseData.status === 'success' && responseData.data) {
        const { display_script, ssml_script } = responseData.data;

        if (!display_script) {
          throw new Error('No display_script received from the server.');
        }

        const cleanedDisplayScript = cleanText(display_script);
        setMeditationScript(cleanedDisplayScript);
        setIsScriptGenerated(true);
        console.log('Script generation complete - isScriptGenerated:', true);
        setEditorMode('script');

        const remainingTime = LoadingController.getRemainingTime();
        await new Promise(resolve => setTimeout(resolve, remainingTime));

        toast.success('Script generated successfully!');
      } else {
        console.error('Invalid response format:', responseData);
        throw new Error(responseData.message || 'Invalid response format from server');
      }
    } catch (error) {
      console.error('Script generation error:', error);
      setError({ message: error.message, type: 'script' });
      toast.error(`Script generation failed: ${error.message}`);
      setIsScriptGenerated(false);
    } finally {
      setHasChanges(false); // Prevent loop by resetting hasChanges
      setIsPersonalizationMode(false);
      setShowDecisionTree(false);
      setIsScriptLoading(false);
      LoadingController.reset();
    }
  }, [
    baseUrl,
    customPrompt,
    selectedOptions,
    selectedVoice,
    isScriptLoading,
    cleanText
  ]);

  // =============== AUDIO COMPLETION HANDLER (TRANSCRIPTION) =============== //
  const handleAudioComplete = useCallback(async (transcription) => {
    if (!transcription) {
      setError({ message: 'Transcription failed. Please try again.', type: 'transcription' });
      setIsTranscribing(false);
      toast.error('Transcription failed. Please try again.');
      return;
    }

    try {
      setCustomPrompt(transcription);
      setIsTranscribed(true);
      setIsTextEditorGlowing(true);
      setTimeout(() => setIsTextEditorGlowing(false), 2000);

      setIsTranscribing(false);
      await new Promise(resolve => setTimeout(resolve, 100));
      setIsScriptLoading(true);
      LoadingController.start();
      await handleGenerateScript(transcription);
    } catch (error) {
      setError({ message: error.message, type: 'script' });
      toast.error(`Error handling audio completion: ${error.message}`);
    }
  }, [handleGenerateScript]);

  // =============== CREATE SESSION (TTS SYNTHESIS) =============== //
  const handleCreateSession = useCallback(async () => {
    if (!meditationScript.trim()) {
      toast.error('Meditation script cannot be empty.');
      return;
    }

    try {
      setIsCreatingSession(true);
      setIsAudioLoading(true);

      const response = await fetch(`${baseUrl}/synthesize-speech`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          text: meditationScript,
          voice: selectedVoice,
          speaking_rate: 1.0,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          `Speech synthesis failed: ${response.status} ${errorData.message || ''}`
        );
      }

      const responseData = await response.json();
      console.log('TTS synthesis response:', responseData);

      if (responseData.status === 'success' && responseData.data) {
        const { file_path, filename } = responseData.data;

        if (!file_path || !filename) {
          throw new Error('Invalid response from server: Missing required data');
        }

        const { isValid } = validateAudioUrl(file_path);

        if (!isValid) {
          throw new Error('Invalid audio URL received');
        }

        setTTSKey(filename);
        setMixedAudioUrl(file_path);
        setIsTTSLoaded(true);
        setIsSessionCreated(true);
        toast.success('Session created successfully!');
      } else {
        throw new Error('Invalid response format from server');
      }

    } catch (err) {
      console.error('Session creation error:', err);
      setError({ 
        message: err.message, 
        type: 'session' 
      });
      toast.error(`Session creation failed: ${err.message}`);
      setIsTTSLoaded(false);
      setIsSessionCreated(false);
    } finally {
      setIsCreatingSession(false);
      setIsAudioLoading(false);
    }
  }, [baseUrl, meditationScript, selectedVoice, validateAudioUrl]);

  useEffect(() => {
    // Reset session-related states when script changes
    if (!isScriptGenerated) {
      setIsSessionCreated(false);
      setIsTTSLoaded(false);
      setIsCreatingSession(false);
    }
  }, [isScriptGenerated]);

  // Add this new effect to handle script changes
  useEffect(() => {
    if (meditationScript !== '') {
      // When meditation script changes, ensure we track generation state correctly
      setIsScriptGenerated(true);
      console.log('Script updated - setting isScriptGenerated:', true);
    }
  }, [meditationScript]);


  // =============== AUDIO MIXING HANDLER =============== //
  const handleAudioMixing = useCallback(async () => {
    if (!selectedMusic || !ttsKey || isLoadingRef.current || isMixingAudioRef.current) {
      return;
    }
  
    try {
      setIsMixingAudio(true);
      isLoadingRef.current = true;
      isMixingAudioRef.current = true;
      setIsMixedAudioLoaded(false);
  
      const currentFilters = {
        ...filters,
        ...(pendingChanges.filters || {})
      };
  
      // Format filters
      const formattedFilters = {};
      Object.entries(currentFilters).forEach(([key, filter]) => {
        if (filter.enabled) {
          formattedFilters[key] = {
            enabled: true,
            ...filter
          };
          delete formattedFilters[key].preset;
        }
      });
  
      const mixPayload = {
        bg_volume: Math.min(Math.max(Number(volume.bg_volume), 0), 1),
        tts_volume: Math.min(Math.max(Number(volume.tts_volume), 0), 1),
        overall_volume: Math.min(Math.max(Number(volume.overall_volume), 0), 1),
        background_music_url: encodeURIComponent(selectedMusic),
        tts_filename: encodeURIComponent(ttsKey),
        filters: formattedFilters
      };
  
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(mixPayload)
      });
  
      // Handle response
      if (!response.ok) {
        throw new Error('Server error');
      }
  
      const responseData = await response.json();
      if (responseData.status === 'success' && responseData.data?.file_path) {
        setNewMixedAudioUrl(responseData.data.file_path);
        setHasChanges(false);
        setIsMixedAudioLoaded(true);
        toast.success('Audio mixed successfully');
      }
  
    } catch (error) {
      console.error('Audio mixing error:', error);
      setError({ message: error.message, type: 'mixing' });
      setIsMixedAudioLoaded(false);
    } finally {
      setIsMixingAudio(false);
      isLoadingRef.current = false;
      isMixingAudioRef.current = false;
    }
  }, [
    selectedMusic,
    ttsKey,
    filters,
    pendingChanges.filters,
    volume,
    baseUrl,
    setNewMixedAudioUrl
  ]);

  // =============== AUDIO ERROR HANDLER =============== //
  const handleAudioError = useCallback((error) => {
    const errorMessages = {
      INVALID_URL: 'Invalid audio URL received',
      EXPIRED: 'Audio URL has expired, please regenerate',
      ACCESS_DENIED: 'Cannot access audio file',
      TIMEOUT: 'Audio loading timed out',
      PLAYBACK: 'Error during playback'
    };

    const message = errorMessages[error.type] || error.message || 'Audio error occurred';
    setError({ message, type: 'audio' });

    if (error.type === 'INVALID_URL' || error.type === 'EXPIRED') {
      setIsTTSLoaded(false);
      setIsSessionCreated(false);
    }

    toast.error(message);
  }, []);

  // =============== PUSH-TO-SPEAK EVENTS =============== //
  const handleRecordingToggle = useCallback((isRecordingNow) => {
    setIsRecording(isRecordingNow);
    setIsRecordingAnimation(isRecordingNow);
  }, []);

  // =============== VOLUME & FILTERS UI HANDLERS =============== //
  const handleVolumeChange = useCallback((type, newValue) => {
      const value = Math.min(Math.max(Number(newValue), 0), 1);
      setVolume(prevVolume => ({
          ...prevVolume,
          [type]: value
      }));
      setHasChanges(true);
  }, [setVolume, setHasChanges]);

  // Filter change handler
  const handleFilterChange = useCallback((filterName, updatedFilter) => {
    logControl('Filter change', {
        filterName,
        updatedFilter
    });

    // Update only pendingChanges.filters
    setPendingChanges(prev => ({
        ...prev,
        filters: {
            ...(prev.filters || {}),
            [filterName]: updatedFilter
        }
    }));

    setHasChanges(true);
  }, [logControl]);

  // Preset selection handler
  const handlePresetSelect = useCallback((filterName, presetLabel) => {
    const preset = presetsMap[filterName]?.find(p => p.label === presetLabel);
    if (preset) {
        setPendingChanges(prev => ({
            ...prev,
            filters: {
                ...(prev.filters || {}),
                [filterName]: {
                    ...prev.filters[filterName],
                    ...preset,
                    enabled: true, // Ensure filter is enabled
                    preset: presetLabel // Track selected preset
                }
            }
        }));
        setHasChanges(true);
        logControl(`Selected preset ${presetLabel} for ${filterName}`, preset);
    }
}, [setPendingChanges, setHasChanges, logControl]);

  // Handle apply changes
  const handleManualApplyChanges = useCallback(async () => {
    if (!hasChanges) return;
  
    try {
      setIsMixingAudio(true);
      
      // Combine current filters with pending changes
      const currentFilters = {
        ...filters,
        ...(pendingChanges.filters || {})
      };
  
      // Format filters payload
      const formattedFilters = {};
      Object.entries(currentFilters).forEach(([key, filter]) => {
        if (filter.enabled) {
          formattedFilters[key] = {
            enabled: true
          };
  
          // Add filter-specific properties
          switch (key) {
            case 'stereoPan':
              formattedFilters[key] = {
                ...formattedFilters[key],
                value: Number(filter.value),
                speed: Number(filter.speed),
                pattern: filter.pattern
              };
              break;
            case 'binauralBeats':
              formattedFilters[key] = {
                ...formattedFilters[key],
                base_frequency: Number(filter.base_frequency),
                beat_frequency: Number(filter.beat_frequency),
                volume: Number(filter.volume)
              };
              break;
            case 'oceanWaves':
              formattedFilters[key] = {
                ...formattedFilters[key],
                wave_frequency: Number(filter.wave_frequency),
                noise_amplitude: Number(filter.noise_amplitude),
                volume: Number(filter.volume)
              };
              break;
            case 'reverb':
              formattedFilters[key] = {
                ...formattedFilters[key],
                value: Number(filter.value)
              };
              break;
          }
        }
      });
  
      console.log('Formatted filters:', formattedFilters);
  
      const mixPayload = {
        bg_volume: Math.min(Math.max(Number(volume.bg_volume), 0), 1),
        tts_volume: Math.min(Math.max(Number(volume.tts_volume), 0), 1),
        overall_volume: Math.min(Math.max(Number(volume.overall_volume), 0), 1),
        background_music_url: encodeURIComponent(selectedMusic),
        tts_filename: encodeURIComponent(ttsKey),
        filters: formattedFilters
      };
  
      console.log('Full payload:', mixPayload);
  
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(mixPayload)
      });
  
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }
  
      const responseData = await response.json();
      if (responseData.status === 'success' && responseData.data) {
        const { file_path } = responseData.data;
        setNewMixedAudioUrl(file_path);
        
        // Update main filter state with pending changes
        if (pendingChanges.filters) {
          setFilters(currentFilters);
        }
        
        setPendingChanges({ volume: null, filters: null });
        setHasChanges(false);
        toast.success('Changes applied successfully');
      }
  
    } catch (error) {
      console.error('Error applying changes:', error);
      setError({ message: error.message, type: 'mixing' });
      toast.error('Failed to apply changes');
    } finally {
      setIsMixingAudio(false);
    }
  }, [
    hasChanges,
    filters,
    pendingChanges,
    volume,
    selectedMusic,
    ttsKey,
    baseUrl,
    setNewMixedAudioUrl,
    setFilters,
    setPendingChanges,
    setHasChanges
  ]);

  useEffect(() => {
    console.log('MeditationApp State Update:', {
      isScriptGenerated,
      isCreatingSession,
      isTTSLoaded,
      isSessionCreated,
      meditationScript: meditationScript ? 'present' : 'empty',
      hasChanges
    });
  }, [isScriptGenerated, isCreatingSession, isTTSLoaded, isSessionCreated, meditationScript, hasChanges]);

  // =============== EFFECTS =============== //
  // Fetch music files on mount
  useEffect(() => {
    const handleFetchMusic = async () => {
      try {
        setIsMusicLoading(true);
        const response = await fetch(`${baseUrl}/load-music`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        if (!response.ok) {
          console.warn('Failed to fetch music files:', response.statusText);
          return;
        }

        const responseData = await response.json();
        console.log('Music data response:', responseData); // Added logging

        // Check response format
        if (responseData.status === 'success' && responseData.data && Array.isArray(responseData.data.musicFiles)) {
          const musicData = responseData.data.musicFiles;
          setMusicFiles(musicData.map(file => ({
            name: file.name.replace(/^Background_Music\/?/i, '').trim(),
            displayName: file.name.replace(/^Background_Music\/?/i, '').replace('.mp3', '').trim()
          })));
          console.log('Music library loaded successfully');
        } else {
          console.warn('Unexpected music data format:', responseData);
          setError({
            message: 'Unexpected music data format received from the server.',
            type: 'music'
          });
        }
      } catch (error) {
        console.warn('Error loading music library:', error);
        setError({
          message: 'Failed to load music library. This might affect background music selection.',
          type: 'music'
        });
      } finally {
        setIsMusicLoading(false);
      }
    };

    handleFetchMusic();
  }, [baseUrl]);

  // =============== MUSIC SELECTION HANDLER =============== //
  const handleMusicSelection = useCallback(async (selectedFileName) => {
    if (isMixingAudioRef.current) {
      toast.info('Audio mixing is already in progress. Please wait.');
      return;
    }
    if (!selectedFileName || (selectedFileName === selectedMusic && !hasChanges)) {
      return;
    }

    setSelectedMusic(selectedFileName);
    setHasChanges(true);
  }, [
    isMixingAudioRef,
    selectedMusic,
    hasChanges
  ]);

  const handleTextEditorChange = useCallback((e) => {
    const newValue = e.target.value;
    if (editorMode === 'prompt') {
      setCustomPrompt(newValue);
    } else {
      setMeditationScript(newValue);
      if (isScriptGenerated) {
        setIsScriptGenerated(false);
      }
    }
  }, [editorMode, isScriptGenerated]);
  
  const handlePromptKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerateScript();
    }
  }, [handleGenerateScript]);
  
  const handleScriptKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleCreateSession();
    }
  }, [handleCreateSession]);
  
  const handleAudioLoaded = useCallback(() => {
    setIsMixedAudioLoaded(true);
    toast.success('Audio loaded successfully!');
  }, []);
  
  const resetForNewMeditation = useCallback(() => {
    setCustomPrompt('');
    setMeditationScript('');
    setSelectedOptions([]);
    setIsScriptGenerated(false);
    setIsSessionCreated(false);
    setIsPersonalizationMode(false);
    setError({ message: null, type: null });
    setIsTextEditorGlowing(false);
    setIsEnhancedEditorGlowing(false);
    setHasChanges(false);
    setIsAudioLoading(false);
    setIsTTSLoaded(false);
    setIsCreatingSession(false);
    setPendingChanges({ volume: null, filters: null });
  }, []);

  // =============== PLAYING STATE CHANGE HANDLER =============== //
  // Define the handlePlayingStateChange function
  const handlePlayingStateChange = useCallback((isPlayingNow) => {
    setIsPlaying(isPlayingNow);
  }, []);

  return (
    <ConversationProvider>
      <div className="App">
        {/* Header */}
        <div className="header-container">
          <div className="header">
            <img src={logo} alt="App Logo" className="app-logo" />
          </div>
        </div>

        {/* Log CreateButton props for debugging */}
        {console.log('CreateButton props:', { isCreatingSession, isScriptGenerated, isTTSLoaded, isSessionCreated })}

        <div className={`meditation-content 
          ${showDecisionTree ? 'decision-tree-open' : ''} 
          ${sidebarState.isOpen ? 'sidebar-open' : ''} 
          ${sidebarState.hasBackdrop ? 'backdrop-active' : ''}`}>

          {/* Decision Tree Trigger */}
          {!isPersonalizationMode && (
            <div className="tree-control-container">
              <div
                className="wave-btn-container"
                onTouchStart={(e) => {
                  e.currentTarget?.classList.add('touched');
                  setTimeout(() => e.currentTarget?.classList.remove('touched'), 3000);
                }}
              >
                <div className="wave-btn-tooltip">
                  Create a guided meditation session with step-by-step options and prompts
                </div>
                <button
                  className="wave-btn"
                  onClick={() => {
                    resetForNewMeditation();
                    setShowDecisionTree(prev => !prev);
                  }}
                  aria-label="Open meditation guide"
                >
                  <span className="wave-effect" />
                  <span className="wave-text">Guide</span>
                </button>
              </div>

              {showDecisionTree && (
                <div className="decision-tree-wrapper">
                  <DecisionTree
                    onScriptGenerated={handleGenerateScript}
                    customPrompt={customPrompt}
                    setCustomPrompt={setCustomPrompt}
                    setMeditationScript={setMeditationScript}
                    isScriptLoading={isScriptLoading}
                    setIsScriptLoading={setIsScriptLoading}
                    PushToSpeakButton={PushToSpeakButton}
                    isPersonalizationMode={isPersonalizationMode}
                    setIsPersonalizationMode={setIsPersonalizationMode}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    setShowDecisionTree={setShowDecisionTree}
                    isScriptGenerated={isScriptGenerated}
                    personalizedNote={personalizedNote}
                    setPersonalizedNote={setPersonalizedNote}
                    selectedVoice={selectedVoice}
                    setIsScriptGenerated={setIsScriptGenerated}
                    setEditorMode={setEditorMode}
                    setError={setError}
                    cleanText={cleanText}
                    LoadingController={LoadingController}
                  />
                </div>
              )}
            </div>
          )}

          {/* Preloaders */}
          <Preloader isLoading={isTranscribing} action="transcribing" />
          <Preloader isLoading={isScriptLoading} action="generating-script" />
          <Preloader isLoading={isCreatingSession} action="creating-session" />
          <Preloader isLoading={isMixingAudio} action="mixing-audio" />

          {/* Main Controls - PushToSpeak */}
          <div className="main-controls">
            <PushToSpeakButton
              onRecordingComplete={handleAudioComplete}
              isRecording={isRecording}
              onRecordingToggle={handleRecordingToggle}
              isTranscribing={isTranscribing}
              setIsTranscribing={setIsTranscribing}
              setIsRecordingAnimation={setIsRecordingAnimation}
              disabled={isLoading}
              isPersonalizationMode={isPersonalizationMode}
              resetHasGeneratedScript={resetForNewMeditation}
            />
          </div>

          {/* Voice Selector */}
          <div className="voice-selector-container">
            <VoiceSelector 
              selectedVoice={selectedVoice} 
              onVoiceChange={setSelectedVoice} 
            />
          </div>

          {/* Merged Text Editor */}
          <MergedTextEditor
            mode={editorMode}
            placeholder={
              editorMode === 'prompt'
                ? isPersonalizationMode
                  ? 'Record or type to personalize your meditation:'
                  : 'Enter a custom prompt...'
                : 'Meditation script goes here...'
            }
            value={editorMode === 'prompt' ? customPrompt : meditationScript}
            onChange={handleTextEditorChange}
            onKeyDown={editorMode === 'prompt' ? handlePromptKeyPress : handleScriptKeyPress}
            isTranscribed={isTranscribed}
            isScriptGenerated={isScriptGenerated}
            onGenerateScript={() => handleGenerateScript(customPrompt)}
            isScriptLoading={isScriptLoading}
            isPersonalizationMode={isPersonalizationMode}
            isGlowing={editorMode === 'prompt' ? isTextEditorGlowing : isEnhancedEditorGlowing}
            setIsGlowing={editorMode === 'prompt' ? setIsTextEditorGlowing : setIsEnhancedEditorGlowing}
            isSessionCreated={isSessionCreated}
          />

          {/* Create Session Button */}
          <CreateButton
            isCreatingSession={isCreatingSession}
            isScriptGenerated={isScriptGenerated}
            isTTSLoaded={isTTSLoaded}
            isSessionCreated={isSessionCreated}
            onClick={handleCreateSession}
          />

          {/* Error Display */}
          {error?.message && (
            <div 
              className={`error-message ${error.type ? `error-${error.type}` : ''}`}
              role="alert"
              aria-live="polite"
            >
              <div className="error-content">
                {error.type === 'audio' && <span className="error-icon">🔊</span>}
                {error.type === 'mixing' && <span className="error-icon">🔄</span>}
                {error.type === 'script' && <span className="error-icon">📝</span>}
                {error.type === 'session' && <span className="error-icon">⚠️</span>}
                {error.type === 'music' && <span className="error-icon">🎵</span>}
                {error.type === 'transcription' && <span className="error-icon">🗣️</span>}
                <span className="error-text">{error.message}</span>
              </div>
              <button 
                className="error-dismiss"
                onClick={() => setError({ message: null, type: null })}
                aria-label="Dismiss error"
                type="button"
              >
                ✕
              </button>
            </div>
          )}

          {/* Audio Player + Volume Controls */}
          {isTTSLoaded && (
            <div className={`audio-controls-container ${sidebarState.isOpen ? 'sidebar-open' : ''}`}>
              <AudioPlayer
                initialAudioUrl={mixedAudioUrl}
                volume={memoizedVolume}
                onError={handleAudioError}
                onPlayingStateChange={handlePlayingStateChange}
                onAudioLoaded={handleAudioLoaded}
                onMusicSelect={handleMusicSelection}
                musicLibrary={memoizedMusicLibrary}
                isSessionCreated={isSessionCreated}
                isMixingAudio={isMixingAudio}
                isMusicLoading={isMusicLoading}
                selectedMusic={selectedMusic}
                isTTSLoaded={isTTSLoaded}
              />

          {selectedMusic && (
            <VolumeControls
                volume={volume}
                setVolume={handleVolumeChange}
                filters={filters}
                onFilterChange={handleFilterChange}
                onApplyFilters={handleManualApplyChanges}
                selectedMusic={selectedMusic}
                ttsKey={ttsKey}
                isMixingAudio={isMixingAudio}
                disabled={isMixingAudio}
                isMusicLoading={isMusicLoading}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
                pendingVolume={pendingChanges.volume}
                pendingFilters={pendingChanges.filters}
                setPendingChanges={setPendingChanges} // Pass the setter
                onPresetSelect={handlePresetSelect} // Corrected here
            />
        )}

              {mixedAudioUrl && !isMixingAudio && (
                <ExportButton text="Export Audio" mixedAudioUrl={mixedAudioUrl} />
              )}
            </div>
          )}

          {/* Toast notifications */}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Slide}
            closeButton={({ closeToast }) => (
              <button
                className="Toastify__close-button"
                onClick={closeToast}
                aria-label="Close notification"
                type="button"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 1L1 13M1 1L13 13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </button>
            )}
          />
        </div>
      </div>
    </ConversationProvider>
  );
};

export default MeditationApp;
