// LoadingIndicator.js
import React from 'react';
import PropTypes from 'prop-types';
import './LoadingIndicator.css';

const LoadingIndicator = ({ message, progress }) => {
  return (
    <div className="loading-indicator">
      <div className="spinner-container">
        <div className="spinner" />
      </div>
      <div className="loading-content">
        <div className="loading-text">{message}</div>
        {typeof progress === 'number' && progress > 0 && (
          <div className="loading-progress">{Math.round(progress)}%</div>
        )}
      </div>
    </div>
  );
};

LoadingIndicator.propTypes = {
  message: PropTypes.string.isRequired,
  progress: PropTypes.number
};

export default LoadingIndicator;