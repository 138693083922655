// ConversationContext.js
import React, { createContext, useState } from 'react';

const ConversationContext = createContext();

const ConversationProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState('initial');
  const [userInput, setUserInput] = useState('');
  const [meditationType, setMeditationType] = useState('');
  const [meditationDuration, setMeditationDuration] = useState('');

  const handleUserInput = (input) => {
    setUserInput(input);
    // Update conversation state
  };

  const value = {
    currentStep,
    userInput,
    meditationType,
    meditationDuration,
    handleUserInput,
    setCurrentStep,
    setMeditationType,
    setMeditationDuration,
  };

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  );
};

export { ConversationContext, ConversationProvider };
