// src/pages/MeditationApp/components/VolumeControls.js

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IoChevronUpSharp, IoChevronDownSharp } from 'react-icons/io5';
import './slider.css';
import { presetsMap } from './FilterPresets';

// Helper function to format percentage
const formatPercentage = (value) => `${Math.round(value * 100)}%`;

// MainVolumeSlider Component
const MainVolumeSlider = ({
    label,
    name,
    value,
    onChange,
    disabled,
    calculateBackground
}) => {
    return (
        <div className="slider-container">
            <label htmlFor={`${name}-slider`} className="control-label">{label}</label>
            <div className="slider-wrapper">
                <input
                    id={`${name}-slider`}
                    type="range"
                    min="0"
                    max="100"
                    step="1"
                    value={Math.round(value * 100)}
                    onChange={(e) => onChange(name, parseFloat(e.target.value) / 100)}
                    disabled={disabled}
                    className="filter-slider neomorphic-slider"
                    style={{ background: calculateBackground(Math.round(value * 100), 0, 100) }}
                    aria-label={`${label} Control`}
                />
                <span className="slider-value">{Math.round(value * 100)}%</span>
            </div>
        </div>
    );
};

MainVolumeSlider.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    calculateBackground: PropTypes.func.isRequired
};

// Control Group Component
const ControlGroup = ({
    label,
    tooltip,
    children,
    id,
    isSelect
}) => {
    return (
        <div className="control-group">
            <div className="tooltip-container">
                <label htmlFor={id} className="control-label">{label}</label>
                {tooltip && (
                    <span className="tooltip" title={tooltip} aria-label={`${label} tooltip`}>
                        ⓘ
                    </span>
                )}
            </div>
            {isSelect ? children : <div className="slider-wrapper">{children}</div>}
        </div>
    );
};

ControlGroup.propTypes = {
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    isSelect: PropTypes.bool
};

ControlGroup.defaultProps = {
    tooltip: null,
    isSelect: false
};

// FilterControls Component
const FilterControls = ({
    filterName,
    filterData,
    onPresetSelect,
    onIndividualChange,
    disabled,
    calculateBackground,
    logControl
}) => {
    // Handler for individual parameter changes
    const handleParamChange = useCallback((paramName, value) => {
        logControl(`${filterName} ${paramName} change`, {
            oldValue: filterData[paramName],
            newValue: value
        });
        onIndividualChange(paramName, value);
    }, [filterName, filterData, onIndividualChange, logControl]);

    const renderPresetControl = () => (
        <ControlGroup
            label="Select Preset"
            tooltip={`Choose a preset for ${filterName.replace(/([A-Z])/g, ' $1').trim()}`}
            id={`${filterName}-preset`}
            isSelect
        >
            <select
                value={filterData.preset || ''}
                onChange={(e) => onPresetSelect(filterName, e.target.value)}
                className="filter-select neomorphic-select"
                disabled={disabled}
                aria-label={`Select preset for ${filterName}`}
            >
                <option value="" disabled>Select a preset</option>
                {presetsMap[filterName]?.map((preset) => (
                    <option key={preset.label} value={preset.label}>
                        {preset.label}
                    </option>
                ))}
            </select>
        </ControlGroup>
    );

    const renderNumberControl = (paramName, label, min, max, step = 0.01, unit = '') => (
        <ControlGroup
            label={label}
            tooltip={`Adjust ${label.toLowerCase()} for ${filterName}`}
            id={`${filterName}-${paramName}`}
        >
            <input
                type="range"
                min={min}
                max={max}
                step={step}
                value={filterData[paramName]}
                onChange={(e) => handleParamChange(paramName, Number(e.target.value))}
                className="filter-slider neomorphic-slider"
                disabled={disabled}
                style={{ background: calculateBackground(filterData[paramName], min, max) }}
                aria-label={`${label} Control`}
            />
            <span className="slider-value">
                {unit === '%' ? formatPercentage(filterData[paramName]) : 
                 `${filterData[paramName]}${unit}`}
            </span>
        </ControlGroup>
    );

    const renderPatternControl = () => (
        <ControlGroup
            label="Pattern"
            tooltip="Select the pattern of the panning effect"
            id={`${filterName}-pattern`}
            isSelect
        >
            <select
                value={filterData.pattern}
                onChange={(e) => handleParamChange('pattern', e.target.value)}
                className="filter-select neomorphic-select"
                disabled={disabled}
                aria-label="Pattern Select"
            >
                <option value="sine">Sine</option>
                <option value="triangle">Triangle</option>
                <option value="square">Square</option>
                <option value="random">Random</option>
                <option value="hold_center">Hold Center</option>
            </select>
        </ControlGroup>
    );

    // Specific controls per filter
    const renderSpecificControls = () => {
        switch (filterName) {
            case 'stereoPan':
                return (
                    <>
                        {renderNumberControl('value', 'Pan Strength', 0, 1, 0.01, '%')}
                        {renderNumberControl('speed', 'Speed', 5, 60, 1, ' sec')}
                        {renderNumberControl('hold_time', 'Hold Time', 0, 60, 1, ' sec')}
                        {renderPatternControl()}
                    </>
                );
            case 'binauralBeats':
                return (
                    <>
                        {renderNumberControl('base_frequency', 'Base Frequency', 20, 500, 1, ' Hz')}
                        {renderNumberControl('beat_frequency', 'Beat Frequency', 0.5, 40, 0.5, ' Hz')}
                        {renderNumberControl('volume', 'Volume', 0, 1, 0.01, '%')}
                    </>
                );
            case 'oceanWaves':
                return (
                    <>
                        {renderNumberControl('noise_amplitude', 'Wave Intensity', 0, 1, 0.01, '%')}
                        {renderNumberControl('wave_frequency', 'Wave Speed', 0.05, 0.5, 0.01, ' Hz')}
                        {renderNumberControl('volume', 'Volume', 0, 1, 0.01, '%')}
                    </>
                );
            case 'reverb':
                // We only want to show the preset dropdown, no reverb sliders
                return null;
        }
    };

    return (
        <div className="filter-controls-content">
            {renderPresetControl()}
            {renderSpecificControls()}
        </div>
    );
};

FilterControls.propTypes = {
    filterName: PropTypes.string.isRequired,
    filterData: PropTypes.object.isRequired,
    onPresetSelect: PropTypes.func.isRequired,
    onIndividualChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    calculateBackground: PropTypes.func.isRequired,
    logControl: PropTypes.func.isRequired
};

// Main VolumeControls Component
const VolumeControls = ({
    baseUrl,
    volume,
    setVolume,
    filters,
    onFilterChange,
    onApplyFilters,
    selectedMusic,
    ttsKey,
    isMixingAudio,
    disabled,
    isMusicLoading,
    hasChanges,
    setHasChanges,
    pendingVolume,
    pendingFilters,
    setPendingChanges,
    onPresetSelect
}) => {
    const [expandedFilter, setExpandedFilter] = useState(null);

    // Logging utility
    const logControl = useCallback((action, data = {}) => {
        const timestamp = new Date().toISOString();
        console.log(`[VolumeControls ${timestamp}] ${action}:`, data);
    }, []);

    // Background gradient calculator
    const calculateBackground = useCallback((value, min = 0, max = 1) => {
        const percentage = ((value - min) / (max - min)) * 100;
        return `linear-gradient(to right, #a020f0 0%, #a020f0 ${percentage}%, #444 ${percentage}%, #444 100%)`;
    }, []);

    // **New Code: Merge filters and pendingFilters to create currentFilters**
    const currentFilters = useMemo(() => ({
        ...filters,
        ...(pendingFilters || {})
    }), [filters, pendingFilters]);

    useEffect(() => {
        console.log('Filters:', filters);
        console.log('Pending Filters:', pendingFilters);
        console.log('Current Filters:', currentFilters);
    }, [filters, pendingFilters, currentFilters]);

    const handleFilterHeaderClick = useCallback((filterName, event) => {
        const isCheckboxClick = event.target.closest('.neomorphic-checkbox');
        const isLabelClick = event.target.closest('.filter-toggle');

        console.log(`Clicked on filter: ${filterName}`);
        console.log(`isCheckboxClick: ${isCheckboxClick !== null}`);
        console.log(`isLabelClick: ${isLabelClick !== null}`);

        if (isCheckboxClick || isLabelClick) {
            console.log('Click on checkbox or label. Not toggling expansion.');
            return;
        }

        console.log('Toggling expansion for filter:', filterName);
        setExpandedFilter(prev => prev === filterName ? null : filterName);
    }, []);

    // Handle volume changes
    const handleVolumeChange = useCallback((type, newValue) => {
        logControl('Volume change', { type, newValue });
        setVolume(type, newValue);
        setHasChanges(true);
    }, [setVolume, logControl, setHasChanges]);

    // Handle individual filter changes
    const handleFilterChangeInternal = useCallback((filterName, paramName, value) => {
        const updatedFilter = {
            ...currentFilters[filterName],
            [paramName]: value
        };

        console.log(`Updating filter: ${filterName}`, updatedFilter);

        setPendingChanges(prev => ({
            ...prev,
            filters: {
                ...(prev.filters || {}),
                [filterName]: updatedFilter
            }
        }));

        setHasChanges(true);
    }, [currentFilters, setPendingChanges, setHasChanges]);

    // Handle filter toggle with defaults preservation and state synchronization
    const handleFilterToggle = useCallback((filterName) => {
        setPendingChanges(prev => ({
            ...prev,
            filters: {
                ...(prev.filters || {}),
                [filterName]: {
                    ...currentFilters[filterName],
                    enabled: !currentFilters[filterName].enabled
                }
            }
        }));
        setHasChanges(true);
    }, [currentFilters, setPendingChanges, setHasChanges]);

    // Preset selection handler
    const handlePresetSelectionInternal = useCallback((filterName, presetLabel) => {
        onPresetSelect(filterName, presetLabel);
    }, [onPresetSelect]);

    // Handle apply changes
    const handleApplyChanges = useCallback(async () => {
        if (!hasChanges) return;
      
        // Build the final filters object:
        const formattedFilters = {};
        Object.entries(currentFilters).forEach(([key, filter]) => {
          if (filter.enabled) {
            // Check if it’s the 'reverb' filter
            if (key === 'reverb') {
              formattedFilters[key] = {
                enabled: true,
                impulse_response_path: filter.impulse_response_path || "",
                wet: parseFloat(filter.wet ?? 1.0),
                dry: parseFloat(filter.dry ?? 1.0)
              };
            } else {
              // handle the other filters (stereoPan, binauralBeats, oceanWaves)...
            }
          }
        });
      
        // Build main request body
        const mixPayload = {
          bg_volume: volume.bg_volume,
          tts_volume: volume.tts_volume,
          overall_volume: volume.overall_volume,
          background_music_url: encodeURIComponent(selectedMusic),
          tts_filename: encodeURIComponent(ttsKey),
          filters: formattedFilters
        };
      
        try {
          // Send to your backend
          const response = await fetch(`${baseUrl}/get-mixed-audio`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(mixPayload)
          });
      
          // handle the response...
        } catch (error) {
          // handle error...
        }
      }, [hasChanges, currentFilters, volume, selectedMusic, ttsKey, baseUrl]);

    return (
        <div className={`volume-controls ${disabled ? 'disabled' : ''}`}>
            {/* Volume Section */}
            <div className="volume-section">
                <div className="volume-sliders">
                    <MainVolumeSlider
                        label="Master Volume"
                        name="overall_volume"
                        value={volume.overall_volume}
                        onChange={handleVolumeChange}
                        disabled={disabled}
                        calculateBackground={calculateBackground}
                    />
                    <MainVolumeSlider
                        label="Voice Volume"
                        name="tts_volume"
                        value={volume.tts_volume}
                        onChange={handleVolumeChange}
                        disabled={disabled}
                        calculateBackground={calculateBackground}
                    />
                    <MainVolumeSlider
                        label="Music Volume"
                        name="bg_volume"
                        value={volume.bg_volume}
                        onChange={handleVolumeChange}
                        disabled={disabled}
                        calculateBackground={calculateBackground}
                    />
                </div>
            </div>

            {/* Filters Section */}
            <div className="filters-section">
                <h3>Audio Effects</h3>
                <div className="filters-grid">
                    {Object.entries(currentFilters).map(([filterName, filterData]) => (
                        <div 
                            key={filterName}
                            className={`filter-wrapper ${expandedFilter === filterName ? 'expanded' : ''}`}
                        >
                            <div
                                className="filter-header"
                                onClick={(e) => handleFilterHeaderClick(filterName, e)}
                                role="button"
                                tabIndex={0}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        handleFilterHeaderClick(filterName, e);
                                    }
                                }}
                                aria-expanded={expandedFilter === filterName}
                                aria-controls={`${filterName}-controls`}
                            >
                                <label className="filter-toggle">
                                    <input
                                        type="checkbox"
                                        checked={filterData.enabled}
                                        onChange={() => handleFilterToggle(filterName)} 
                                        disabled={disabled}
                                        className="neomorphic-checkbox"
                                        aria-label={`Toggle ${filterName} filter`}
                                    />
                                    <span>{filterName.replace(/([A-Z])/g, ' $1').trim()}</span>
                                </label>
                                <div className="chevron-container">
                                    {expandedFilter === filterName ? 
                                        <IoChevronUpSharp /> : 
                                        <IoChevronDownSharp />}
                                </div>
                            </div>

                            {/* Render filter-controls regardless of filterData.enabled */}
                            {expandedFilter === filterName && (
                                <div className={`filter-controls ${filterData.enabled ? 'enabled' : 'disabled'}`} id={`${filterName}-controls`}>
                                    <FilterControls
                                        filterName={filterName}
                                        filterData={filterData}
                                        onPresetSelect={handlePresetSelectionInternal}
                                        onIndividualChange={(paramName, value) => handleFilterChangeInternal(filterName, paramName, value)}
                                        disabled={disabled}
                                        calculateBackground={calculateBackground}
                                        logControl={logControl}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Apply Changes Button */}
                {hasChanges && (
                    <div className="filters-apply-button-container">
                        <button
                            className="filters-apply-button"
                            onClick={onApplyFilters} // Correct handler
                            disabled={disabled || isMixingAudio}
                        >
                            {isMixingAudio ? 'Applying Changes...' : 'Apply Changes'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

VolumeControls.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    volume: PropTypes.shape({
        overall_volume: PropTypes.number.isRequired,
        tts_volume: PropTypes.number.isRequired,
        bg_volume: PropTypes.number.isRequired
    }).isRequired,
    setVolume: PropTypes.func.isRequired,
    filters: PropTypes.shape({
        stereoPan: PropTypes.object,
        binauralBeats: PropTypes.object,
        oceanWaves: PropTypes.object,
        reverb: PropTypes.object
    }).isRequired,
    onFilterChange: PropTypes.func.isRequired,
    onApplyFilters: PropTypes.func.isRequired, // Ensure this is passed correctly
    selectedMusic: PropTypes.string.isRequired,
    ttsKey: PropTypes.string.isRequired,
    isMixingAudio: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isMusicLoading: PropTypes.bool.isRequired,
    hasChanges: PropTypes.bool.isRequired,
    setHasChanges: PropTypes.func.isRequired,
    pendingVolume: PropTypes.object,
    pendingFilters: PropTypes.object,
    setPendingChanges: PropTypes.func.isRequired,
    onPresetSelect: PropTypes.func.isRequired
};

VolumeControls.defaultProps = {
    // baseUrl: 'https://api.pause.site', // Uncomment if needed
    pendingVolume: null,
    pendingFilters: null
};

export default VolumeControls;