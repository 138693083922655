// src/pages/MeditationApp/components/FilterPresets.js

export const presetsMap = {
    stereoPan: [
        { label: "Slow Pan", value: 0.5, speed: 30, hold_time: 10, pattern: 'sine' },
        { label: "Fast Pan", value: 0.7, speed: 10, hold_time: 5, pattern: 'triangle' },
        { label: "Random Pan", value: 0.6, speed: 20, hold_time: 0, pattern: 'random' }
    ],
    binauralBeats: [
        { label: "Delta (Deep Sleep)", base_frequency: 100, beat_frequency: 2, volume: 0.3 },
        { label: "Theta (Meditation)", base_frequency: 150, beat_frequency: 5, volume: 0.3 },
        { label: "Alpha (Relaxation)", base_frequency: 200, beat_frequency: 10, volume: 0.3 },
        { label: "Beta (Concentration)", base_frequency: 250, beat_frequency: 20, volume: 0.3 },
        { label: "Gamma (Cognition)", base_frequency: 300, beat_frequency: 40, volume: 0.3 }
    ],
    oceanWaves: [
        { label: "Calm Beach", wave_frequency: 0.05, noise_amplitude: 0.2, volume: 0.3 },
        { label: "Gentle Waves", wave_frequency: 0.1, noise_amplitude: 0.3, volume: 0.3 },
        { label: "Rolling Waves", wave_frequency: 0.2, noise_amplitude: 0.4, volume: 0.3 },
        { label: "Stormy Sea", wave_frequency: 0.3, noise_amplitude: 0.5, volume: 0.3 }
    ],
    reverb: [
        {
          label: "Small Chapel",
          impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/SmallChapel.wav",
          wet: 0.4,
          dry: 1.0
        },
        {
          label: "Concert Hall",
          impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/ConcertHall.wav",
          wet: 0.6,
          dry: 1.0
        },
        {
          label: "Cathedral",
          impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/Cathedral.wav",
          wet: 0.7,
          dry: 1.0
        },
        {
          label: "Deep Cave",
          impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/DeepCave.wav",
          wet: 0.8,
          dry: 1.0
        },
        {
          label: "Healing Temple",
          impulse_response_path: "s3://pausebeta20-meditation-assets/IRs/HealingTemple.wav",
          wet: 0.5,
          dry: 1.0
        }
      ]
    };