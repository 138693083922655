/** src/pages/MeditationApp/components/PushToSpeakButton.js **/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import './PushToSpeakButton.css';
import { THEMES, useTheme } from '../../Dashboard/components/contexts/ThemeContext';
import { toast } from 'react-toastify'; // Ensure react-toastify is installed

const PushToSpeakButton = ({
  onRecordingComplete,
  isRecording,
  onRecordingToggle,
  isTranscribing,
  setIsTranscribing, // Ensure this prop is passed correctly
  setIsRecordingAnimation,
  disabled,
  isPersonalizationMode,
  resetHasGeneratedScript,
}) => {
  const [localIsRecording, setLocalIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isMobile, setIsMobile] = useState(false);
  const tooltipTimeoutRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { themeConfig } = useTheme();

  const checkMobile = useCallback(() => setIsMobile(window.innerWidth <= 768), []);

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [checkMobile]);

  useEffect(() => {
    setLocalIsRecording(isRecording);
  }, [isRecording]);

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    const selectedTheme = THEMES[themeConfig.theme];
    if (selectedTheme) {
      Object.entries(selectedTheme.colors).forEach(([key, value]) => {
        root.style.setProperty(key, value);
      });
    }
  }, [themeConfig]);

  const handleTooltipToggle = useCallback(() => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    setShowTooltip(true);
    tooltipTimeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  }, []);

  const handleTranscription = useCallback(async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio_recording.wav');
    
    try {
      console.log('Starting transcription request...');
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/transcribe-audio`, {
        method: 'POST',
        body: formData,
        credentials: 'include'
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Transcription HTTP error:', errorData);
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Full Transcription Response:', JSON.stringify(data, null, 2));
      
      // Correctly access the nested transcription
      const transcriptionText = data?.data?.transcription ?? '';
      
      if (transcriptionText) {
        console.log('Transcription successful:', transcriptionText);
        onRecordingComplete(transcriptionText);
      } else {
        console.error('No transcription in response:', data);
        throw new Error('No transcription available');
      }
    } catch (error) {
      console.error('Error handling transcription:', error);
      onRecordingComplete(null);
      toast.error(`Transcription failed: ${error.message}. Please try again.`);
    } finally {
      setIsRecordingAnimation(false);
      setIsTranscribing(false);
    }
  }, [onRecordingComplete, setIsRecordingAnimation, setIsTranscribing]);

  const startRecording = useCallback(async () => {
    if (!localIsRecording && !disabled) {
      resetHasGeneratedScript();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.start();
        setLocalIsRecording(true);
        onRecordingToggle(true);
      } catch (err) {
        console.error('Error starting the MediaRecorder:', err);
        toast.error(`Failed to start recording: ${err.message}. Please check your microphone settings and try again.`);
      }
    }
  }, [localIsRecording, disabled, onRecordingToggle, resetHasGeneratedScript]);

  const stopRecording = useCallback(() => {
    if (localIsRecording && mediaRecorderRef.current?.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setLocalIsRecording(false);
      onRecordingToggle(false);
      setIsRecordingAnimation(true);
  
      mediaRecorderRef.current.onstop = async () => {
        setIsTranscribing(true);

        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];

        try {
          if (audioBlob.size > 0) {
            await handleTranscription(audioBlob);
          } else {
            throw new Error('No audio data recorded');
          }
        } catch (err) {
          console.error(err);
          toast.error(err.message);
        } finally {
          setIsTranscribing(false);
        }
      };
  
      mediaRecorderRef.current.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        setIsTranscribing(false);
        setIsRecordingAnimation(false);
        toast.error('Recording error: ' + event.error.message);
      };
    }
  }, [
    localIsRecording,
    onRecordingToggle,
    setIsRecordingAnimation,
    setIsTranscribing,
    handleTranscription
  ]);

  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    if (isMobile) {
      handleTooltipToggle();
      startRecording();
    }
  }, [startRecording, handleTooltipToggle, isMobile]);

  const handleMouseEnter = useCallback(() => {
    if (!isMobile) handleTooltipToggle();
  }, [isMobile, handleTooltipToggle]);

  const handleMouseLeave = useCallback(() => {
    if (!isMobile) stopRecording();
  }, [isMobile, stopRecording]);

  const handleTouchEnd = useCallback((e) => {
    e.preventDefault();
    stopRecording();
  }, [stopRecording]);

  return (
    <div className={`PushButton-wrapper ${isMobile ? 'mobile' : ''} ${showTooltip ? 'touched' : ''}`}>
      <div className={`PushButton-tooltip ${showTooltip ? 'visible' : ''}`}>
        {isPersonalizationMode
          ? "Push and hold to record your personalization preferences"
          : "Push and hold to speak your meditation preferences"}
      </div>
      <button
        className={`PushButton ${disabled ? 'disabled' : ''} ${localIsRecording ? 'active' : ''} ${isMobile ? 'mobile' : ''}`}
        onMouseDown={isMobile ? undefined : startRecording}
        onMouseUp={isMobile ? undefined : stopRecording}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={stopRecording}
        onContextMenu={(e) => e.preventDefault()}
        disabled={disabled || isTranscribing}
        aria-label={localIsRecording ? 'Stop recording' : 'Start recording'}
        aria-pressed={localIsRecording}
      >
        <svg className="PushButton-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M6 19v-7a1 1 0 0 1 2 0v7a1 1 0 0 1-2 0zm5-14v10a1 1 0 0 0 2 0V5a1 1 0 0 0-2 0zm5 4v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-2 0z"/>
        </svg>
      </button>
      <div className={`PushButton-label ${isMobile ? 'mobile' : ''}`}>
        {localIsRecording ? 'Recording...' 
          : isTranscribing ? 'Transcribing...' 
          : isPersonalizationMode ? 'Push to Personalize' 
          : 'Push to Talk'}
      </div>
    </div>
  );
};

export default PushToSpeakButton;
