// src/pages/MeditationApp/components/AudioPlayer.js

import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import { BsPlayFill, BsPauseFill, BsStopFill, BsSkipForward, BsSkipBackward } from 'react-icons/bs';
import debounce from 'lodash/debounce';
import LoadingIndicator from './LoadingIndicator';
import MusicLibraryDropdown from './MusicLibraryDropdown';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AudioPlayer.css';

const LOAD_TIMEOUT = 30000;
const INITIALIZATION_DEBOUNCE = 300;

const formatTime = (seconds) => {
  if (!isFinite(seconds)) return '0:00';
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
  return `${minutes}:${secs}`;
};

const AudioPlayer = ({
  initialAudioUrl,
  volume,
  onError,
  onPlayingStateChange,
  onAudioLoaded,
  onMusicSelect,
  musicLibrary,
  isSessionCreated,
  isMixingAudio,
  isMusicLoading,
  selectedMusic, 
  // Removed setSelectedMusic as it is unused
  isTTSLoaded
}) => {
  // Refs
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const scrubberRef = useRef(null);
  const isMountedRef = useRef(true);
  const loadingTimeoutRef = useRef(null);
  const urlLoadingRef = useRef(null);
  const audioContextRef = useRef(null);


  // State
  const [audioState, setAudioState] = useState({
    isLoading: false,
    loadProgress: 0,
    isPlaying: false,
    currentTime: 0,
    duration: 0,
    isPlayerReady: false,
    error: null
  });

  // Update cleanup function
  const cleanup = useCallback(() => {
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.pause();
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
      } catch (err) {
        console.error('Cleanup error:', err);
      }
    }

    // Cleanup audio context
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }

    if (loadingTimeoutRef.current) {
      clearTimeout(loadingTimeoutRef.current);
      loadingTimeoutRef.current = null;
    }

    setAudioState(prev => ({
      ...prev,
      isLoading: false,
      loadProgress: 0,
      isPlayerReady: false,
      isPlaying: false,
      currentTime: 0,
      duration: 0,
      error: null
    }));
  }, []);

  const handleMusicSelectionInternal = useCallback((selectedFileName) => {
    // Call parent's onMusicSelect directly
    onMusicSelect(selectedFileName);
  }, [onMusicSelect]);

  // Add timeupdate handler
  useEffect(() => {
    if (waveSurferRef.current && audioState.isPlayerReady) {
      const handleTimeUpdate = () => {
        setAudioState(prev => ({
          ...prev,
          currentTime: waveSurferRef.current.getCurrentTime()
        }));
      };

      waveSurferRef.current.on('audioprocess', handleTimeUpdate);
      return () => waveSurferRef.current?.un('audioprocess', handleTimeUpdate);
    }
  }, [audioState.isPlayerReady]);

  // Handle errors
  const handleError = useCallback((error) => {
    console.error('AudioPlayer error:', error);
    setAudioState(prev => ({
      ...prev,
      isLoading: false,
      error: {
        message: error.message || 'An error occurred while loading audio',
        type: error.type || 'unknown'
      }
    }));
    
    if (onError) {
      onError(error);
    }

    toast.error(error.message || 'Failed to load audio');
  }, [onError]);

  const initializeWaveSurfer = useCallback(async (audioUrl) => {
    if (!audioUrl || isMixingAudio) return;

    cleanup();

    try {
      setAudioState(prev => ({ ...prev, isLoading: true, error: null }));

      // Create audio context
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();

      const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#1e2023',
        progressColor: '#7d12ff',
        cursorColor: '#ffffff',
        barWidth: 2,
        barGap: 2,
        responsive: true,
        height: 80,
        normalize: true,
        hideScrollbar: true,
        fillParent: true,
        minPxPerSec: 100,
        backend: 'WebAudio',
        audioContext: audioContextRef.current
      });

      // Set up event handlers
      wavesurfer.on('ready', () => {
        if (!isMountedRef.current) return;

        clearTimeout(loadingTimeoutRef.current);
        
        setAudioState(prev => ({
          ...prev,
          isPlayerReady: true,
          duration: wavesurfer.getDuration(),
          isLoading: false,
          loadProgress: 100
        }));

        wavesurfer.setVolume(volume.overall_volume);
        
        if (onAudioLoaded) {
          onAudioLoaded();
        }
      });

      wavesurfer.on('loading', (percent) => {
        if (!isMountedRef.current) return;
        
        setAudioState(prev => ({
          ...prev,
          loadProgress: percent
        }));
      });

      wavesurfer.on('error', (err) => {
        if (!isMountedRef.current) return;
        
        handleError({
          type: 'wavesurfer',
          message: err.message || 'Error loading audio'
        });
      });

      waveSurferRef.current = wavesurfer;
      await wavesurfer.load(audioUrl);

    } catch (err) {
      handleError({
        type: 'initialization',
        message: err.message || 'Failed to initialize audio player'
      });
    }
  }, [cleanup, handleError, isMixingAudio, volume.overall_volume, onAudioLoaded]);

  // Handler functions
  const handlePlayPause = useCallback(() => {
    if (!waveSurferRef.current || !audioState.isPlayerReady || isMixingAudio) {
      return;
    }

    try {
      if (audioState.isPlaying) {
        waveSurferRef.current.pause();
        setAudioState(prev => ({ ...prev, isPlaying: false }));
        if (onPlayingStateChange) {
          onPlayingStateChange(false);
        }
      } else {
        waveSurferRef.current.play();
        setAudioState(prev => ({ ...prev, isPlaying: true }));
        if (onPlayingStateChange) {
          onPlayingStateChange(true);
        }
      }
    } catch (err) {
      handleError({
        type: 'playback',
        message: 'Error controlling playback'
      });
    }
  }, [audioState.isPlayerReady, audioState.isPlaying, isMixingAudio, handleError, onPlayingStateChange]);

  const handleSeek = useCallback((direction) => {
    if (!waveSurferRef.current || !audioState.isPlayerReady) {
      return;
    }

    try {
      const currentTime = waveSurferRef.current.getCurrentTime();
      const newTime = Math.max(0, Math.min(audioState.duration, currentTime + direction * 5));
      waveSurferRef.current.seekTo(newTime / audioState.duration);
    } catch (err) {
      handleError({
        type: 'seek',
        message: 'Error seeking audio position'
      });
    }
  }, [audioState.isPlayerReady, audioState.duration, handleError]);

  const handleStop = useCallback(() => {
    if (!waveSurferRef.current || !audioState.isPlayerReady) {
      return;
    }

    try {
      waveSurferRef.current.stop();
      setAudioState(prev => ({ ...prev, currentTime: 0, isPlaying: false }));
      if (onPlayingStateChange) {
        onPlayingStateChange(false);
      }
    } catch (err) {
      handleError({
        type: 'stop',
        message: 'Error stopping playback'
      });
    }
  }, [audioState.isPlayerReady, handleError, onPlayingStateChange]);

  const handleScrubberClick = useCallback((e) => {
    if (!waveSurferRef.current || !scrubberRef.current || !audioState.isPlayerReady) {
      return;
    }

    try {
      const rect = scrubberRef.current.getBoundingClientRect();
      const pos = (e.clientX - rect.left) / rect.width;
      waveSurferRef.current.seekTo(pos);
    } catch (err) {
      handleError({
        type: 'scrubber',
        message: 'Error adjusting playback position'
      });
    }
  }, [audioState.isPlayerReady, handleError]);

  const isAudioReady = useMemo(() => (
    audioState.isPlayerReady && !isMixingAudio && waveSurferRef.current
  ), [audioState.isPlayerReady, isMixingAudio]);

  // Effects
  useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
      cleanup();
    };
  }, [cleanup]);

  useEffect(() => {
    if (!initialAudioUrl || isMixingAudio) return;

    const loadAudio = async () => {
      try {
        await initializeWaveSurfer(initialAudioUrl);
      } catch (err) {
        if (isMountedRef.current) {
          handleError({
            type: 'loading',
            message: 'Failed to load audio'
          });
        }
      }
    };

    const timeoutId = setTimeout(loadAudio, INITIALIZATION_DEBOUNCE);
    return () => clearTimeout(timeoutId);
  }, [initialAudioUrl, isMixingAudio, initializeWaveSurfer, handleError]);

  useEffect(() => {
    if (waveSurferRef.current && audioState.isPlayerReady && !isMixingAudio) {
      waveSurferRef.current.setVolume(volume.overall_volume);
    }
  }, [volume.overall_volume, audioState.isPlayerReady, isMixingAudio]);

  useEffect(() => {
    if (audioState.error) {
      cleanup();
      if (onError) {
        onError(audioState.error);
      }
    }
  }, [audioState.error, cleanup, onError]);

  const getLoadingMessage = useCallback(() => {
    if (isMixingAudio) return 'Mixing audio...';
    if (isMusicLoading) return 'Loading music...';
    return 'Loading audio...';
  }, [isMixingAudio, isMusicLoading]);

  return (
    <div className="audio-player-container">
      {audioState.error && (
        <div className="error-message" role="alert">
          {audioState.error.message}
        </div>
      )}

      <div className="waveform-loading-container">
        <div ref={waveformRef} className="waveform-container">
          {(audioState.isLoading || isMixingAudio || isMusicLoading) && (
            <div className="waveform-loading-overlay">
              <LoadingIndicator
                message={getLoadingMessage()}
                progress={audioState.loadProgress}
              />
            </div>
          )}
          <div className="waveform" />
        </div>
      </div>

      <div className="scrubber-wrapper">
        <span className="current-time">{formatTime(audioState.currentTime)}</span>
        <div
          className="scrubber-container"
          ref={scrubberRef}
          onClick={handleScrubberClick}
          role="slider"
          aria-valuemin={0}
          aria-valuemax={audioState.duration}
          aria-valuenow={audioState.currentTime}
          tabIndex={0}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              handleScrubberClick(e);
            }
          }}
        >
          <div
            className="scrubber-handle"
            style={{ left: `${(audioState.currentTime / audioState.duration) * 100 || 0}%` }}
          />
        </div>
        <span className="total-duration">{formatTime(audioState.duration)}</span>
      </div>

      <div className="wavesurfer-controls">
        <button
          onClick={() => handleSeek(-5)}
          disabled={!audioState.isPlayerReady}
          className="control-btn"
          aria-label="Skip backward 5 seconds"
        >
          <BsSkipBackward className="icon" />
        </button>

        <button
          onClick={handlePlayPause}
          disabled={!isAudioReady}
          className={`control-btn ${audioState.isPlaying ? 'playing' : ''}`}
          aria-label={audioState.isPlaying ? 'Pause' : 'Play'}
        >
          {audioState.isPlaying ? (
            <BsPauseFill className="icon" />
          ) : (
            <BsPlayFill className="icon" />
          )}
        </button>

        <button
          onClick={handleStop}
          disabled={!audioState.isPlayerReady}
          className="control-btn"
          aria-label="Stop"
        >
          <BsStopFill className="icon" />
        </button>

        <button
          onClick={() => handleSeek(5)}
          disabled={!audioState.isPlayerReady}
          className="control-btn"
          aria-label="Skip forward 5 seconds"
        >
          <BsSkipForward className="icon" />
        </button>
      </div>

      <div className="music-library-section">
        <MusicLibraryDropdown
          selectedMusic={selectedMusic}
          onMusicSelect={handleMusicSelectionInternal}  // Use the internal handler
          isSessionCreated={isSessionCreated}
          isMixingAudio={isMixingAudio}
          isMusicLoading={isMusicLoading}
          musicFiles={musicLibrary}
          error={audioState.error?.type === 'music' ? audioState.error.message : null}
        />
      </div>
    </div>
  );
};


AudioPlayer.propTypes = {
  initialAudioUrl: PropTypes.string.isRequired,
  volume: PropTypes.shape({
    overall_volume: PropTypes.number.isRequired
  }).isRequired,
  onError: PropTypes.func,
  onPlayingStateChange: PropTypes.func,
  onAudioLoaded: PropTypes.func,
  onMusicSelect: PropTypes.func.isRequired,
  musicLibrary: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  isSessionCreated: PropTypes.bool.isRequired,
  isMixingAudio: PropTypes.bool.isRequired,
  isMusicLoading: PropTypes.bool.isRequired,
  selectedMusic: PropTypes.string.isRequired,
  // Removed setSelectedMusic as it is unused
  isTTSLoaded: PropTypes.bool.isRequired
};

AudioPlayer.defaultProps = {
  onError: null,
  onPlayingStateChange: null,
  onAudioLoaded: null
};

export default AudioPlayer;
