// src/pages/LandingPage.js

import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import Header from './Header';
import useGSAPAnimations from './hooks/useGSAPAnimations';
import useCalendar, { MONTH_NAMES, DAYS, MOOD_OPTIONS } from './hooks/useCalendar';
import Spline from '@splinetool/react-spline';
import gsap from 'gsap';
import 'aos/dist/aos.css';
import './Landing.css';
import './calstyle.css';
import { Network, Target, ArrowRight, Brain } from 'lucide-react';
import { Fingerprint, Smile, BarChart3 } from 'lucide-react';


// Asset imports
import logo from '../../assets/logo_transparent.png';
import iphone from '../../assets/iphone.png';
import headphone from '../../assets/headphone.png';
import brainGif from '../../assets/brain.gif';
import plasticityGif from '../../assets/nplas.gif';
import pauseAppIcon from '../../assets/Pause_app.png';
import appleStoreIcon from '../../assets/apple.png';
import googlePlayIcon from '../../assets/google.png';
import writersBlockAudio from '../../assets/writersblock.mp3';
import moodgif from '../../assets/magic.gif';

import {
  Music2 as SoundQualityIcon,
  Headphones as ImmersiveAudioIcon,
  Volume as EvenSoundIcon,
  User as PersonalizeIcon,
  Share2 as ShareIcon,
  Globe as LanguageIcon,
} from 'lucide-react';


const FEATURES_DATA = [
  {
    icon: SoundQualityIcon,
    title: 'Choose Your Sound Quality',
    description: 'Immerse yourself in crystal-clear sound that makes you feel as if you’re right there.',
  },
  {
    icon: ImmersiveAudioIcon,
    title: 'Immersive Audio Experience',
    description: 'Surround yourself with soothing sounds enhanced by 3D stereo depth.',
  },
  {
    icon: EvenSoundIcon,
    title: 'Even Volume, Smooth Sessions',
    description: 'Experience consistent sound levels for seamless meditation sessions.',
  },
  {
    icon: PersonalizeIcon,
    title: 'Personalize Your Peace',
    description: 'Blend background tunes with guided meditations tailored to your mood.',
  },
  {
    icon: ShareIcon,
    title: 'Share Your Calm',
    description: 'Distribute custom meditation mixes to promote tranquility and well-being.',
  },
  {
    icon: LanguageIcon,
    title: 'Your Voice, Your Language',
    description: 'Customize your meditation journey with 20 voices across 40+ languages.',
  },
];

const LandingPage = () => {
  // Refs
  const landingRef = useRef(null);
  const headerRef = useRef(null);
  const howItWorksRef = useRef(null);
  const cognitiveRef = useRef(null);
  const downloadRef = useRef(null);
  const audioFeaturesRef = useRef(null);
  const mindfulnessFeaturesRef = useRef(null);
  const iphoneImgRef = useRef(null);
  const headphoneRef = useRef(null);
  const backgroundLayerRef = useRef(null);
  const scrollTextRef = useRef(null);
  const dynamicTextRef = useRef(null);
  const intentTextRef = useRef(null);
  const calendarRef = useRef(null);
  const calendarWrapperRef = useRef(null);
  const calendarHeaderRef = useRef(null);
  const calendarDaysRef = useRef(null);
  const splineContainerRef = useRef(null);
  const mindfulnessRef = useRef(null);
  const auroraBgRef = useRef(null);
  const featuresRef = useRef(null);
  const nextSectionRef = useRef(null);
  const factTextRefs = useRef([]);
  const factsContainerRef = useRef(null);
  const calendarContainerRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false); // Add this line

  // State
  const [isSplineLoaded, setIsSplineLoaded] = useState(false);
  const [activeSection, setActiveSection] = useState('landing');
  const [scrollDirection, setScrollDirection] = useState('down');
  const [isMobile, setIsMobile] = useState(false);

  const {
    dayRefs,
    currentYear,
    currentMonth,
    showMoodSelector,
    activeCell,
    moods,
    isAnimating,
    moodSelectorRef,
    handleMonthChange,
    handleMoodSelect,
    generateCalendarDays,
    setShowMoodSelector,
    setActiveCell,
  } = useCalendar();

  const { refresh } = useGSAPAnimations({
    landingRef,
    howItWorksRef,
    mindfulnessRef,
    cognitiveRef,
    downloadRef,
    iphoneImgRef,
    headphoneRef,
    mindfulnessFeaturesRef,
    backgroundLayerRef,
    scrollTextRef,
    dynamicTextRef,
    intentTextRef,
    splineContainerRef,
    audioFeaturesRef,
    nextSectionRef,
    auroraBgRef,
    factsContainerRef,
    factTextRefs,
    calendarHeaderRef,
    calendarDaysRef,
    calendarContainerRef,
    headerRef,
  });

  // Effects
  useEffect(() => {
    refresh();
  }, [refresh]);

  useLayoutEffect(() => {
    if (iphoneImgRef.current && headphoneRef.current) {
      gsap.fromTo(
        [iphoneImgRef.current, headphoneRef.current],
        { opacity: 0, scale: 0.8 },
        {
          opacity: 1,
          scale: 1,
          duration: 1,
          ease: 'back.out(1.7)',
          stagger: 0.2,
        }
      );
    }
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (showMoodSelector) {
      const previousFocus = document.activeElement;
      const firstMoodOption = moodSelectorRef.current?.querySelector('.mood-option');
      if (firstMoodOption) firstMoodOption.focus();

      const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
          const focusableElements = moodSelectorRef.current?.querySelectorAll('.mood-option, button.close-button');
          if (!focusableElements) return;

          const firstElement = focusableElements[0];
          const lastElement = focusableElements[focusableElements.length - 1];

          if (e.shiftKey && document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          } else if (!e.shiftKey && document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }

        if (e.key === 'Escape') {
          setShowMoodSelector(false);
          setActiveCell(null);
          if (previousFocus instanceof HTMLElement) previousFocus.focus();
        }
      };

      document.addEventListener('keydown', handleKeyDown);
      return () => document.removeEventListener('keydown', handleKeyDown);
    }
  }, [showMoodSelector, setShowMoodSelector, setActiveCell]);

  const handleScroll = useCallback(() => {
    const scrollPosition = window.pageYOffset;
    const windowHeight = window.innerHeight;

    // Update header scroll state
    setIsScrolled(scrollPosition > 50);

    const sections = [
      { id: 'features', ref: featuresRef },
      { id: 'ai-mindfulness', ref: mindfulnessRef },
      { id: 'cognition', ref: cognitiveRef },
    ];

    sections.forEach(({ id, ref }) => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        if (rect.top <= windowHeight / 2 && rect.bottom >= windowHeight / 2) {
          setActiveSection(id);
        }
      }
    });

    setScrollDirection(scrollPosition > window.lastScrollPosition ? 'down' : 'up');
    window.lastScrollPosition = scrollPosition;
  }, []);

  useEffect(() => {
    window.lastScrollPosition = 0;
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const scrollToDownload = useCallback(() => {
    downloadRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  const handleSplineLoad = useCallback(() => {
    setIsSplineLoaded(true);
  }, []);

  return (
    <div className="landing-page-container" data-scroll-container>

      <Header 
        ref={headerRef}
        activeSection={activeSection}
        isScrolled={isScrolled} // Updated to use state
      />
      
      <section
        id="landing"
        className={`section landing-section ${activeSection === 'landing' ? 'active' : ''}`}
        ref={landingRef}
        data-scroll-section
        data-anim
        style={{ 
          marginTop: 0,
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          className="background-layer parallax-bg"
          ref={backgroundLayerRef}
          data-scroll
          data-scroll-speed="-4"
        />
        <div className="content fade-in">
          <div className="logo-container" data-scroll data-scroll-speed="2" data-anim>
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <h2 data-scroll data-scroll-speed="1" data-anim>
            Experience a fully customized meditation through AI.
          </h2>
        </div>
      </section>
    
      {/* How It Works Section */}
      <section
        id="how-it-works"
        className={`section how-it-works-section ${activeSection === 'how-it-works' ? 'active' : ''}`}
        ref={howItWorksRef}
        data-scroll-section
      >
        <div className="content">
          <h1 className="intent-text" ref={intentTextRef}>
            Speak your intent.
          </h1>
          <div className="text-input-container fade-in-up" data-scroll data-scroll-speed="0.5">
            <div className="text-input">
              <p id="scrollText" ref={dynamicTextRef} className="scroll-text"></p>
            </div>
          </div>
          <div className="pulsating-ripple-container fade-in-up" data-scroll data-scroll-speed="0.5">
            <div className="ripple ripple-1"></div>
            <div className="ripple ripple-2"></div>
            <div className="ripple ripple-3"></div>
          </div>
        </div>
        <div className="device iphone-container slide-in-right" data-scroll data-scroll-speed="1.5">
          <img
            src={iphone}
            alt="iPhone with App"
            className="iphone-img hover-float"
            ref={iphoneImgRef}
          />
        </div>
        <div className="device airpods-container slide-in-left" data-scroll data-scroll-speed="1.5">
          <img
            src={headphone}
            alt="Airpods"
            className="airpods-img hover-float"
            ref={headphoneRef}
          />
        </div>
      </section>
  
      {/* Benefits Section */}
      <section
        id="benefits"
        className={`section ${activeSection === 'benefits' ? 'active' : ''}`}
        data-scroll-section
      >
        <div className="star-layer"></div>
        <div id="spline-benefits-container" className="spline-container" ref={splineContainerRef}>
          <Spline
            scene="https://prod.spline.design/njqJAdaP9ASxxu10/scene.splinecode"
            onLoad={handleSplineLoad}
          />
          {!isSplineLoaded && (
            <div className="loading-overlay">
              <div className="spinner"></div>
            </div>
          )}
        </div>
        <div className="content mindfulness-text" data-scroll data-scroll-speed="1">
          <h2>Time out, tune in.</h2>
          <p>
            Take a moment to align your energy with what serves you best. Let us guide you
            towards your optimal well-being.
          </p>
        </div>
      </section>
      
      {/* Mindfulness AI Section */}
      <section
        id="ai-mindfulness"
        className={`section ${activeSection === 'ai-mindfulness' ? 'active' : ''}`}
        ref={mindfulnessRef}
        data-scroll-section
        data-anim
      >
        <div className="aurora-bg" ref={auroraBgRef} data-scroll data-scroll-speed="-2" />
        
        <div className="content mindfulness-ai-content" data-scroll data-scroll-speed="1">
          <h2 className="section-title">How are you feeling today?</h2>
      
          <div className="calendar-container" data-anim ref={calendarContainerRef}>
            <div className="calendar-section" ref={calendarWrapperRef}>
              {/* Calendar Header */}
              <div className="calendar-header" ref={calendarHeaderRef}>
                <button
                  className="year-change"
                  onClick={() => handleMonthChange(-1)}
                  disabled={isAnimating}
                  aria-label="Previous Month"
                >
                  &lt;
                </button>
                <div className="month-year">
                  <span className="month-picker">{MONTH_NAMES[currentMonth]}</span>
                  <span id="year">{currentYear}</span>
                </div>
                <button
                  className="year-change"
                  onClick={() => handleMonthChange(1)}
                  disabled={isAnimating}
                  aria-label="Next Month"
                >
                  &gt;
                </button>
              </div>
      
              <div className="calendar-body">
                <div className="calendar-week-day">
                  {DAYS.map((day) => (
                    <div key={day} className="week-day-label">{day}</div>
                  ))}
                </div>
      
                <div
                  className="calendar-days"
                  ref={calendarDaysRef}
                  data-active-cell={activeCell}
                  data-show-selector={showMoodSelector}
                >
                  {generateCalendarDays()}
                </div>
      
                {showMoodSelector && activeCell && (
                  <div
                    className={`mood-selector-container ${showMoodSelector ? 'active' : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMoodSelector(false);
                      setActiveCell(null);
                    }}
                  >
                    <div
                      className="mood-selector-overlay"
                      ref={moodSelectorRef}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="selected-date">
                        {(() => {
                          const displayDate = new Date(activeCell);
                          // Ensure we're working with the local date
                          return displayDate.toLocaleDateString('en-US', {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            timeZone: 'UTC',
                          });
                        })()}
                      </div>
                      <div className="mood-options-grid">
                        {Object.entries(MOOD_OPTIONS)
                          .filter(([key]) => key !== 'None')
                          .map(([key, { emoji }]) => (
                            <button
                              key={key}
                              type="button"
                              className={`mood-option ${moods[activeCell] === key ? 'selected' : ''}`}
                              onClick={() => handleMoodSelect(key, activeCell)}
                              aria-pressed={moods[activeCell] === key}
                            >
                              <span className="mood-emoji">{emoji}</span>
                              <span className="mood-label">{key.toUpperCase()}</span>
                            </button>
                          ))}
                        <button
                          className="mood-option none-option"
                          onClick={() => handleMoodSelect('None', activeCell)}
                        >
                          <span className="mood-emoji">❌</span>
                          <span className="mood-label">NONE</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
    
          {/* Mindfulness Features */}
          <div className="mindfulness-features" ref={mindfulnessFeaturesRef} data-anim>
            {/* Features */}
            <div className="mindfulness-feature" data-anim>
              <div className="feature-icon-wrapper">
                <Fingerprint size={30} className="feature-icon" />
              </div>
              <div className="feature-content">
                <h3>Personalized Mindfulness</h3>
                <p>Set up personalized meditation schedules tailored to your lifestyle and emotional patterns.</p>
              </div>
            </div>
    
            <div className="mindfulness-feature" data-anim>
              <div className="feature-icon-wrapper">
                <Smile size={40} className="feature-icon" />
              </div>
              <div className="feature-content">
                <h3>AI-Driven Sentiment Analysis</h3>
                <p>Our AI sentiment detection technology analyzes your mood patterns to provide personalized meditation recommendations.</p>
              </div>
            </div>
    
            <div className="mindfulness-feature" data-anim>
              <div className="feature-icon-wrapper">
                <BarChart3 size={20} className="feature-icon" />
              </div>
              <div className="feature-content">
                <h3>Smart Mood Tracking</h3>
                <p>Track your emotional journey with our interactive calendar. Visualize patterns and celebrate your progress over time.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      {/* Cognitive Section */}
      <section
        id="cognition"
        className={`section cognitive-section ${activeSection === 'cognition' ? 'active' : ''}`}
        ref={cognitiveRef}
        data-scroll-section
        data-anim
      >
        <div className="spline2-container" data-anim>
          <div className="gradient-overlay" data-anim>
            <img
              src={brainGif}
              alt="Neural Network Visualization"
              className="fact-gif2"
              data-anim
            />
          </div>
        </div>
    
        <div className="title-container" data-anim>
          <h2 className="section-title">Powered by Neuroscience: Amplifying Your Mental Agility</h2>
          <p className="section-subtitle">
            Harness the power of AI-driven meditation to enhance your cognitive capabilities.
          </p>
        </div>
    
        <div className="cognitive-content" ref={factsContainerRef}>
          <article className="fact" ref={(el) => (factTextRefs.current[0] = el)} data-anim>
            <div className="fact-media">
              <img src={plasticityGif} alt="Neural Plasticity Visualization" className="fact-gif" />
              <div className="gradient-line"></div>
            </div>
            <div className="fact-content">
              <div className="fact-icon">
                <Network size={48} />
              </div>
              <h3 className="fact-title">Neural Plasticity</h3>
              <p className="fact-description">
                Unlock your brain's natural ability to form new neural pathways and enhance cognitive
                flexibility through guided meditation.
              </p>
            </div>
          </article>
    
          <article className="fact" ref={(el) => (factTextRefs.current[1] = el)} data-anim>
            <div className="fact-media">
              <div className="spline-wrapper">
                <Spline
                  scene="https://prod.spline.design/h4t2E4IW3IFzr6ax/scene.splinecode"
                  className="spline2"
                />
              </div>
              <div className="gradient-line"></div>
            </div>
            <div className="fact-content">
              <div className="fact-icon">
                <Target size={48} />
              </div>
              <h3 className="fact-title">Focus Mastery</h3>
              <p className="fact-description">
                Enhance your concentration and mental clarity with AI-powered guidance tailored to your
                cognitive state.
              </p>
            </div>
          </article>
    
          <article className="fact" ref={(el) => (factTextRefs.current[2] = el)} data-anim>
            <div className="fact-media">
              <img src={moodgif} alt="Mental Resilience Visualization" className="fact-gif" />
              <div className="gradient-line"></div>
            </div>
            <div className="fact-content">
              <div className="fact-icon">
                <Brain size={48} />
              </div>
              <h3 className="fact-title">Mental Resilience</h3>
              <p className="fact-description">
                Build emotional strength and adaptability through personalized meditation techniques and
                stress management.
              </p>
            </div>
          </article>
        </div>
    
        {/* Updated button to link to sign-up page */}
        <a href="/signup" className="cta-button" data-anim>
          Get Started
          <ArrowRight size={24} className="cta-icon" />
        </a>
      </section>
    
      {/* Features Section */}
      <section
        id="features"
        className={`section ${activeSection === 'features' ? 'active' : ''}`}
        ref={featuresRef}
        data-scroll-section
        data-anim
      >
        <div className="text-style" data-anim>
          <h2>Transform Your Meditation with Crystal-Clear Sound</h2>
        </div>
        <div className="audio-player-container" data-anim>
          <audio controls data-anim>
            <source src={writersBlockAudio} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <p>Create a meditation about overcoming writer's block.</p>
        </div>
        <div className="features-container" data-anim>
          {FEATURES_DATA.map((feature, index) => (
            <div className="feature-item" key={index} data-anim>
              <div className="feature-icon-wrapper" data-anim>
                <feature.icon size={40} color="#bb86fc" />
              </div>
              <div className="feature-text" data-anim>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    
      <section 
        id="download" 
        className={`section ${activeSection === 'download' ? 'active' : ''}`}
        ref={downloadRef}
        data-scroll-section
      >
        <div className="content">
          <div className="dlicon-container">
            <img
              src={pauseAppIcon}
              alt="Pause App Icon"
              className="app-icon"
            />
          </div>
          <h2>Download Now</h2>
          <div className="store-buttons">
            <a
              href="https://example.com/app-store"
              target="_blank"
              rel="noopener noreferrer"
              className="store-link"
            >
              <img src={appleStoreIcon} alt="Download on App Store" />
            </a>
            <a
              href="https://example.com/play-store"
              target="_blank"
              rel="noopener noreferrer"
              className="store-link"
            >
              <img src={googlePlayIcon} alt="Get it on Google Play" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
