import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './CreateButton.css';

const CreateButton = ({
  isCreatingSession = false,
  isScriptGenerated = false,
  isTTSLoaded = false,
  isSessionCreated = false,
  onClick
}) => {
  // Add debugging effect
  useEffect(() => {
    console.log('CreateButton State:', {
      isCreatingSession,
      isScriptGenerated,
      isTTSLoaded,
      isSessionCreated,
      isDisabled: !isScriptGenerated || isCreatingSession || isSessionCreated
    });
  }, [isCreatingSession, isScriptGenerated, isTTSLoaded, isSessionCreated]);

  // Disable condition
  const isDisabled = !isScriptGenerated || isCreatingSession || isSessionCreated;

  // Combine class names based on state
  const buttonClasses = [
    'wave-btn',
    isCreatingSession && 'creating-session',
    isScriptGenerated && !isSessionCreated && 'script-generated',
    isSessionCreated && 'session-created'
  ].filter(Boolean).join(' ');

  return (
    <button
      className={buttonClasses}
      onClick={onClick}
      disabled={isDisabled}
      aria-disabled={isDisabled}
      aria-label="Create Meditation Session"
    >
      {isCreatingSession
        ? 'Creating...'
        : isSessionCreated
        ? 'Session Created'
        : 'Create Session'}
    </button>
  );
};

CreateButton.propTypes = {
  isCreatingSession: PropTypes.bool.isRequired,
  isScriptGenerated: PropTypes.bool.isRequired,
  isTTSLoaded: PropTypes.bool.isRequired,
  isSessionCreated: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CreateButton;