import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import './MusicLibraryDropdown.css';

const MusicLibraryDropdown = ({
  selectedMusic,
  onMusicSelect,
  isSessionCreated,
  isMixingAudio,
  isMusicLoading,
  musicFiles,
  error
}) => {
  // Debug logging
  useEffect(() => {
    console.log('MusicLibraryDropdown state:', {
      selectedMusic,
      isSessionCreated,
      isMixingAudio,
      musicFilesCount: musicFiles.length,
      availableOptions: musicFiles.map(m => m.name)
    });
  }, [selectedMusic, isSessionCreated, isMixingAudio, musicFiles]);

  const handleMusicSelection = (e) => {
    const selectedFileName = e.target.value;
    console.log('Music selection change:', { 
      oldValue: selectedMusic, 
      newValue: selectedFileName 
    });
    
    if (selectedFileName) {
      if (!isSessionCreated) {
        toast.info('Please create a session first before selecting music');
        return;
      }
      onMusicSelect(selectedFileName);
    }
  };

  // Find the current music file object
  const selectedMusicFile = musicFiles.find(music => music.name === selectedMusic);

  return (
    <div className={`music-library-dropdown ${isMixingAudio ? 'mixing' : ''}`}>
      <select
        value={selectedMusic || ''}
        onChange={handleMusicSelection}
        disabled={!isSessionCreated || isMixingAudio || isMusicLoading}
      >
        <option value="">
          {selectedMusicFile ? selectedMusicFile.displayName : 
          isMixingAudio ? 'Mixing audio...' :
          !isSessionCreated ? 'Create session first' :
          'Select Background Music'}
        </option>
        {musicFiles.map((music) => (
          <option 
            key={music.name} 
            value={music.name}
          >
            {music.displayName || music.name}
          </option>
        ))}
      </select>
      {isMixingAudio && (
        <div className="loading-indicator mixing">
          <div className="spinner" />
        </div>
      )}
    </div>
  );
};

MusicLibraryDropdown.propTypes = {
  selectedMusic: PropTypes.string.isRequired,
  onMusicSelect: PropTypes.func.isRequired,
  isSessionCreated: PropTypes.bool.isRequired,
  isMixingAudio: PropTypes.bool.isRequired,
  isMusicLoading: PropTypes.bool.isRequired,
  musicFiles: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  error: PropTypes.string
};

export default MusicLibraryDropdown;