/** src/pages/MeditationApp/components/MergedTextEditor.js **/

import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import './MergedTextEditor.css'; // Assuming you have corresponding CSS

const MergedTextEditor = ({
  mode,
  placeholder,
  value,
  onChange,
  onKeyDown,
  isTranscribed,
  isScriptGenerated,
  onGenerateScript,
  isScriptLoading,
  isPersonalizationMode,
  isGlowing,
  setIsGlowing,
  isSessionCreated,
}) => {
  const textAreaRef = useRef(null);
  const containerRef = useRef(null);

  const cleanText = useCallback((text) => {
    if (!text) return '';

    let cleanedText = text
      // Remove pause markers with their surrounding spaces
      .replace(/\s*\[Pause\]\s*/g, ' ')
      .replace(/\s*\[Long Pause\]\s*/g, ' ')
      // Fix contractions
      .replace(/(\w)\s*'\s*s\b/g, "$1's")
      .replace(/(\w)\s*'\s*re\b/g, "$1're")
      .replace(/(\w)\s*'\s*ve\b/g, "$1've")
      .replace(/(\w)\s*'\s*ll\b/g, "$1'll")
      .replace(/(\w)\s*'\s*t\b/g, "$1't")
      .replace(/(\w)\s*'\s*d\b/g, "$1'd")
      .replace(/(\w)\s*'\s*m\b/g, "$1'm")
      // Fix punctuation spacing
      .replace(/\s+([,.!?])/g, '$1')
      .replace(/([,.!?])(?!\s)/g, '$1 ')
      // Remove any remaining markers
      .replace(/\[.*?\]/g, '')
      // Remove extra spaces
      .replace(/\s+/g, ' ')
      .trim();

    return cleanedText;
  }, []);

  const handleTextChange = useCallback((e) => {
    const newValue = e.target.value;
    onChange({ target: { value: newValue } });
  }, [onChange]);

  // Focus and glow effect
  useEffect(() => {
    if (isGlowing && textAreaRef.current) {
      textAreaRef.current.focus();
      containerRef.current?.classList.add('glow-effect');
    } else if (containerRef.current) {
      containerRef.current?.classList.remove('glow-effect');
    }
  }, [isGlowing]);

  // Fade in effect
  useEffect(() => {
    if (isScriptGenerated && textAreaRef.current) {
      textAreaRef.current.classList.add('fade-in');
      const timer = setTimeout(() => {
        textAreaRef.current?.classList.remove('fade-in');
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isScriptGenerated]);

  // Script generated glow effect
  useEffect(() => {
    if (isScriptGenerated && containerRef.current) {
      containerRef.current.classList.add('script-generated-glow');
      const timer = setTimeout(() => {
        containerRef.current?.classList.remove('script-generated-glow');
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isScriptGenerated]);

  // Prevent default space behavior in Safari
  const handleKeyDown = (e) => {
    if (e.key === ' ' && e.target.selectionStart === e.target.selectionEnd) {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const currentValue = e.target.value;
      const newValue =
        currentValue.substring(0, cursorPosition) +
        ' ' +
        currentValue.substring(cursorPosition);

      e.target.value = newValue;

      // Update cursor position
      requestAnimationFrame(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
        // Trigger onChange with the new value
        handleTextChange({ target: { value: newValue } });
      });
    }

    // Call the original onKeyDown handler
    onKeyDown?.(e);
  };

  return (
    <div
      ref={containerRef}
      className={`text-container
        ${isGlowing ? 'glow-effect' : ''}
        ${isScriptGenerated ? 'script-generated-glow' : ''}
        ${isSessionCreated ? 'session-created' : ''}
        ${mode}`}
    >
      <textarea
        ref={textAreaRef}
        className="text-area"
        placeholder={placeholder}
        value={value}
        onChange={handleTextChange}
        onKeyDown={handleKeyDown}
        spellCheck="true"
        autoCapitalize="sentences"
        readOnly={isScriptGenerated && !isPersonalizationMode} // Make read-only if script is generated and not in personalization mode
      />
      {mode === 'prompt' && isPersonalizationMode && !isScriptGenerated && (
        <div className="generate-script-btn-container">
          <button
            className="wave-btn"
            onClick={onGenerateScript}
            disabled={isScriptLoading}
            aria-label="Generate Script"
          >
            <span className="wave-effect" />
            <span className="wave-text">Generate Script</span>
          </button>
        </div>
      )}
    </div>
  );
};

MergedTextEditor.propTypes = {
  mode: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  isTranscribed: PropTypes.bool,
  isScriptGenerated: PropTypes.bool.isRequired,
  onGenerateScript: PropTypes.func.isRequired,
  isScriptLoading: PropTypes.bool.isRequired,
  isPersonalizationMode: PropTypes.bool.isRequired,
  isGlowing: PropTypes.bool.isRequired,
  setIsGlowing: PropTypes.func.isRequired,
  isSessionCreated: PropTypes.bool.isRequired,
};

MergedTextEditor.defaultProps = {
  placeholder: '',
  onKeyDown: null,
  isTranscribed: false,
};

export default MergedTextEditor;
