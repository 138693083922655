import React, { createContext, useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  sendEmailVerification,
  getRedirectResult
} from 'firebase/auth';
import { auth } from '../utils/firebase';

// Mobile detection utility
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Create the context
const AuthContext = createContext();

// Hook for consuming AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle redirect result
  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result?.user) {
          console.log('Redirect sign-in successful');
          setUser(result.user);
        }
      } catch (error) {
        console.error('Redirect sign-in error:', error);
        setError(error);
      }
    };

    // Check for redirect result on mount
    handleRedirectResult();

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      console.log('Auth state changed:', currentUser ? 'user logged in' : 'no user');
      if (currentUser) {
        try {
          await currentUser.reload();
          setUser(auth.currentUser);
        } catch (reloadError) {
          console.error('Error reloading user:', reloadError);
          setError(reloadError);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Login with email and password
  const login = async (email, password) => {
    try {
      setLoading(true);
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      return userCredential.user;
    } catch (loginError) {
      console.error('Login Error:', loginError);
      throw loginError;
    } finally {
      setLoading(false);
    }
  };

  // Login with Google
  const loginWithGoogle = async () => {
    try {
      setLoading(true);
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account'
      });

      if (isMobileDevice()) {
        console.log('Mobile device detected, using signInWithRedirect');
        await signInWithRedirect(auth, provider);
        // Result will be handled by the redirect handler in useEffect
      } else {
        console.log('Desktop device detected, using signInWithPopup');
        const result = await signInWithPopup(auth, provider);
        setUser(result.user);
        return result.user;
      }
    } catch (googleError) {
      console.error('Google Login Error:', googleError);
      throw googleError;
    } finally {
      setLoading(false);
    }
  };

  // Login with Apple
  const loginWithApple = async () => {
    try {
      setLoading(true);
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');

      if (isMobileDevice()) {
        console.log('Mobile device detected, using signInWithRedirect');
        await signInWithRedirect(auth, provider);
        // Result will be handled by the redirect handler in useEffect
      } else {
        console.log('Desktop device detected, using signInWithPopup');
        const result = await signInWithPopup(auth, provider);
        setUser(result.user);
        return result.user;
      }
    } catch (appleError) {
      console.error('Apple Login Error:', appleError);
      throw appleError;
    } finally {
      setLoading(false);
    }
  };

  // Signup with email and password
  const signup = async (email, password) => {
    try {
      setLoading(true);
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      setUser(userCredential.user);
      return userCredential.user;
    } catch (signupError) {
      console.error('Signup Error:', signupError);
      throw signupError;
    } finally {
      setLoading(false);
    }
  };

  // Logout
  const logout = async () => {
    try {
      setLoading(true);
      await signOut(auth);
      setUser(null);
    } catch (logoutError) {
      console.error('Logout Error:', logoutError);
      throw logoutError;
    } finally {
      setLoading(false);
    }
  };

  // Reset Password
  const resetPassword = async (email) => {
    try {
      setLoading(true);
      await sendPasswordResetEmail(auth, email);
    } catch (resetError) {
      console.error('Password Reset Error:', resetError);
      throw resetError;
    } finally {
      setLoading(false);
    }
  };

  // Context value
  const value = {
    user,
    loading,
    error,
    login,
    loginWithGoogle,
    loginWithApple,
    signup,
    logout,
    resetPassword,
    isAuthenticated: !!user,
    emailVerified: user?.emailVerified || false,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Route Guards
export const PrivateRoute = ({ children }) => {
  const { user, loading, emailVerified } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!emailVerified) {
    return <Navigate to="/check-email" replace />;
  }

  return children;
};

export const PublicRoute = ({ children }) => {
  const { user, loading, emailVerified } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user && emailVerified) {
    return <Navigate to="/app" replace />;
  }

  return children;
};

export const EmailVerificationRoute = ({ children }) => {
  const { user, loading, emailVerified } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (emailVerified) {
    return <Navigate to="/app" replace />;
  }

  return children;
};

export default AuthProvider;